import React from 'react'
import { Link } from 'gatsby'

import { slugify } from '../helpers'

const Breadcrumbs = ({loc, subloc}) => {
    
    return (
        <ul className="breadcrumbs">
            <li className="breadcrumb">
                <Link to="/" >Home</Link>
            </li>
            <li className={`breadcrumb ${!subloc && "active"}`}>
                {subloc ?  (<Link to={slugify(loc)} >{loc}</Link>) : (<b>{loc}</b>) }
                
            </li>
            {subloc && 
                (<li className={`breadcrumb ${subloc && "active"}`}>
                    <b>{subloc}</b>
            </li>
                )}
        </ul>
    )
}

export default Breadcrumbs
