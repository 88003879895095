import React from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Breadcrumbs from "../../components/ui/breadcrumbs"

import pillar from "../../../static/images/pillars/pillar-2.jpg"

class RootIndex extends React.Component {
    render() {
        return (
            <Layout location={this.props.location}>
                <Seo title="Pillar 2" />
                <div className="container single-pillar">
                    <img src={pillar} alt="happy woman in the kitchen" />
                    <Breadcrumbs loc="pillars" subloc="pillar 2" />
                    <h1>Pillar 2</h1>
                    <h2>Avoid injuries and falls by retraining balance</h2>
                    <p>
                        Falls are the leading cause of injury related visits to
                        emergency departments in the United States and the
                        primary etiology of accidental deaths in persons over
                        the age of 65. Risk factors for falls in the elderly
                        include increasing age, medication use, cognitive
                        impairment and sensory deficits. Certain exercises can
                        prevent falls and fall related injuries. It is
                        recommended that older adults with poor mobility should
                        perform physical activity three or more days per week to
                        enhance balance and prevent falls.
                    </p>

                    <h3>Balance becomes more and more important as we age</h3>
                    <p>
                        There are three body systems that control balance. The
                        first is your eyes, the second is your inner ear, and
                        the third is proprioception from your feet. All are
                        critical but as we age, proprioception is the control
                        mechanism that suffers the most and needs the most
                        maintenance to continue functioning properly. We have
                        sensors in our feet that use the nerves in our legs and
                        spinal cord like a telephone wire to tell our brain what
                        the surface we are standing on is like: flat, uneven, a
                        hill, etc. Our brain uses this information to fire the
                        muscles in our legs and core to keep us from falling
                        over. As we age, those nerve sensors and pathways get
                        slower and the communication can get a little mixed up.
                    </p>
                    <p>
                        Retraining your balance improves visual- spatial
                        processing- the ability to perceive where objects are in
                        space and their distance from each other. It increases
                        overall attention levels and the ability to focus,
                        improves your gait speed and agility!{" "}
                    </p>
                    <Link className="button" to="/pillars/pillar-3/">
                        Next pillar
                    </Link>
                </div>
            </Layout>
        )
    }
}

export default RootIndex
